/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import OpenLoadsItemDetail from "./OpenLoadsItemDetail";
import {
  ErrorResponse,
  LoadSummary,
} from "../../../../types/types";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import TextButton from "../../../../components/atoms/TextButton";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchOpenLoadPDF } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OpenLoads";

type Props = {
  children?: ReactNode;
  index: number;
  line: LoadSummary;
};

const OpenLoadsItem: React.FC<Props> = ({ index, line }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | ErrorResponse | undefined>();
  const [isExpanded, setIsExpanded] = useState(false);
  const { lines } = line;
  const { status } = useAppSelector((state) => state.document.openLoads);

  const ctx = useContext(PDFContext);

  const clickHandler = () => {
    setIsExpanded((prev) => !prev);
  };

  const downloadPDFHandler = async () => {
    setError(undefined);
    dispatch(fetchOpenLoadPDF({ customerId: line.billingCustomerId, id: line.loadId }))
    .unwrap()
    .then((result) => {
      ctx?.setFile(result);
    })
    .catch((error) => setError(error));
  };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => setError(undefined)}
        />
      )}
      <tr css={[index % 2 === 0 && tw`bg-white`, index % 2 !== 0 && tw`bg-nucor-table-alternate-row-background`, tw`hover:bg-nucor-yellow`]}>
        <td>
          <div css={tw`flex gap-2 py-1 items-center`}>
            <button onClick={clickHandler}>
              <FontAwesomeIcon
                css={tw`ml-1`}
                icon={isExpanded ? faSquareMinus : faSquarePlus}
              />
            </button>
            {line.hideDocumentLink ? (
              <span css={tw`font-semibold text-nucor-gray`}>
                 {line.loadId}
              </span>
            ) : (
              <TextButton disabled={status === "pending"} onClick={downloadPDFHandler}>
                {line.loadId}
              </TextButton>
            )}
          </div>
        </td>
        <td>{formatDateToShort(line.createDate)}</td>
        <td className="whitespace-nowrap">{line.siteLabel}</td>
        <td>{line.deliveryRouteId}</td>
        <td className="cursor-pointer" onClick={clickHandler}>
          <span>{`${line.billingCustomerId} - ${line.customerName}`}</span>
        </td>
        <td>
          <ul>
            {line.shippingCustomerLabelList?.map((label, index) => (
              <li key={index}>{label}</li>
            ))}
          </ul>
        </td>
        <td className="cursor-pointer text-center" onClick={clickHandler}>
          {line.status === "Shipped"
            ? `Shipped ${formatDateToShort(line.scheduledShipDate)}`
            : formatDateToShort(line.scheduledShipDate)}
        </td>
        <td className="cursor-pointer" onClick={clickHandler}>
          {line.deliveryMode}
        </td>
        <td className="cursor-pointer" onClick={clickHandler}>
          {line.proBillNumber}
        </td>
        <td className="cursor-pointer text-right" onClick={clickHandler}>
          {numberFormatter(line.totalWeight)}
        </td>
        <td>{line.carrier}</td>
      </tr>
      {isExpanded && (
        <tr>
          <td css={tw`!p-2`} colSpan={11}>
            <table className="ntp-portal-table" css={tw`w-full`}>
              <thead>
                <tr>
                  <th>Mill</th>
                  <th>Ship To</th>
                  <th>PO #</th>
                  <th>Order Line</th>
                  <th>Description</th>
                  <th>Length</th>
                  <th>Weight</th>
                </tr>
              </thead>
              <tbody>
                {lines.map((item, index) => (
                  <OpenLoadsItemDetail lineDetail={item} key={index} />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default OpenLoadsItem;
