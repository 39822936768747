/** @jsxImportSource @emotion/react */
import React, { ReactNode, useRef } from "react";
import { Bar, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartState } from "./AccountChart";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId, selectCurrentCustomerSummary } from "../../../../store/customer.reducer";
import { useNavigate } from "react-router-dom";
import { updateReleaseOrderSearchCriteria } from "../../../../store/shipping.reducer";
import { updateOrderSearchCriteria, updatePackingSlipSearchCriteria } from "../../../../store/document.reducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  children?: ReactNode;
  chartType: ChartState;
};

const chartDetails = {
  readyToShip: {
    title: "Ready To Ship",
    xTitle: "Days Past Original Due Date",
    yTitle: "Tons",
  },
  orderHistory: {
    title: "Order History",
    xTitle: "Month",
    yTitle: "Tons",
  },
  shipmentHistory: {
    title: "Shipment History",
    xTitle: "Month",
    yTitle: "Tons",
  },
};

const Chart: React.FC<Props> = ({ chartType = "readyToShip" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentCustomerSummary = useAppSelector(selectCurrentCustomerSummary);

  const releaseOrdersSearchCriteria = useAppSelector(
    (state) => state.shipping.releaseOrder.searchCriteria
  );

  const ordersSearchCriteria = useAppSelector(
    (state) => state.document.orders
  );

  const packingSlipSearchCriteria = useAppSelector(
    (state) => state.document.packingSlip
  );

  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);

  const chartOptions = {
    responsive: true,
    aspectRatio: 1.5 / 1,
    plugins: {
      title: {
        display: true,
        text: chartDetails[chartType].title,
        color: "black",
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        grid: { display: false },
        title: {
          display: true,
          text: chartDetails[chartType].xTitle,
          color: "black",
        },
        ticks: {
          color: "black",
        },
      },
      y: {
        title: {
          display: true,
          text: chartDetails[chartType].yTitle,
          color: "black",
        },
        ticks: { color: "black" },
      },
    },
    layout: {
      padding: 10,
    },
  };

  if (chartType === "readyToShip") {
    const readyToShipChartRef = useRef<ChartJS<"bar">>(null);
    return (
      <Bar
        ref={readyToShipChartRef}
        css={[{ border: "1px solid #bbb", background: "#f0f0f0" }]}
        options={{ ...chartOptions }}
        data={{
          labels: ["0-30", "30-60", "60-90", "90+"],
          datasets: [
            {
              backgroundColor: "rgb(217,36,0)",
              data: [
                currentCustomerSummary?.readyToShipWeight0To30, 
                currentCustomerSummary?.readyToShipWeight30To60, 
                currentCustomerSummary?.readyToShipWeight60To90, 
                currentCustomerSummary?.readyToShipWeightOver90
              ],
            },
          ],
        }}
        onClick={event => {
          if (readyToShipChartRef.current) {
            const dataSet = getDatasetAtEvent(readyToShipChartRef.current, event);
            if (dataSet.length > 0) {
              const element = getElementAtEvent(readyToShipChartRef.current, event);
              if (element.length > 0) {
                dispatch(
                  updateReleaseOrderSearchCriteria({
                    ...releaseOrdersSearchCriteria,
                    dateRangeType: "all",
                    siteKey: "ALL",
                    shipToCustomerId: "all",
                    page: 1,
                  })
                );
                navigate({
                  pathname: "/portal/myshipping/orderreleaselist",
                  search: selectedCustomerId
                    ? `?customer_id=${selectedCustomerId}`
                    : undefined,
                });
              }
            }
          }
        }}
        onMouseMove={event => {
          if (readyToShipChartRef.current) {
            const element = getElementAtEvent(readyToShipChartRef.current, event);
            event.currentTarget.style.cursor = element.length > 0 ? "pointer" : "default";
          }
        }}
      />
    );
  }

  if (chartType === "orderHistory") {
    const orderHistoryChartRef = useRef<ChartJS<"bar">>(null);
    return (
      <Bar
        ref={orderHistoryChartRef}
        css={[{ border: "1px solid #bbb", background: "#f0f0f0" }]}
        options={{ ...chartOptions }}
        data={{
          labels: currentCustomerSummary?.orderHistory.map(
            (item) => item.monthYear
          ),
          datasets: [
            {
              backgroundColor: "rgb(217,36,0)",
              data: currentCustomerSummary?.orderHistory.map(
                (item) => item.weight
              ),
            },
          ],
        }}
        onClick={event => {
          if (orderHistoryChartRef.current) {
            const dataSet = getDatasetAtEvent(orderHistoryChartRef.current, event);
            if (dataSet.length > 0) {
              const element = getElementAtEvent(orderHistoryChartRef.current, event);
              if (element.length > 0) {
                dispatch(
                  updateOrderSearchCriteria({
                    ...ordersSearchCriteria,
                    myCustomers: false,
                    shipToCustomerId: "all",
                    orderStatus: "all",
                    siteKey: "ALL",
                    dateRangeType: "last30Days",
                    page: 1,
                  })
                );
                navigate({
                  pathname: "/portal/mydocuments/orderlist",
                  search: selectedCustomerId
                    ? `?customer_id=${selectedCustomerId}`
                    : undefined,
                });
              }
            }
          }
        }}
        onMouseMove={event => {
          if (orderHistoryChartRef.current) {
            const element = getElementAtEvent(orderHistoryChartRef.current, event);
            event.currentTarget.style.cursor = element.length > 0 ? "pointer" : "default";
          }
        }}
      />
    );
  }

  if (chartType === "shipmentHistory") {
    const shipmentHistoryChartRef = useRef<ChartJS<"bar">>(null);
    return (
      <Bar
        ref={shipmentHistoryChartRef}
        css={[{ border: "1px solid #bbb", background: "#f0f0f0" }]}
        options={{ ...chartOptions }}
        data={{
          labels: currentCustomerSummary?.shipmentHistory.map(
            (item) => item.monthYear
          ),
          datasets: [
            {
              backgroundColor: "rgb(217,36,0)",
              data: currentCustomerSummary?.shipmentHistory.map(
                (item) => item.weight
              ),
            },
          ],
        }}
        onClick={event => {
          if (shipmentHistoryChartRef.current) {
            const dataSet = getDatasetAtEvent(shipmentHistoryChartRef.current, event);
            if (dataSet.length > 0) {
              const element = getElementAtEvent(shipmentHistoryChartRef.current, event);
              if (element.length > 0) {
                dispatch(
                  updatePackingSlipSearchCriteria({
                    ...packingSlipSearchCriteria,
                    myCustomers: false,
                    shipToCustomerId: "all",
                    deliveryMode: "all",
                    siteKey: "ALL",
                    dateRangeType: "last30Days",
                    page: 1,
                  })
                );
                navigate({
                  pathname: "/portal/mydocuments/shippinglist",
                  search: selectedCustomerId
                    ? `?customer_id=${selectedCustomerId}`
                    : undefined,
                });
              }
            }
          }
        }}
        onMouseMove={event => {
          if (shipmentHistoryChartRef.current) {
            const element = getElementAtEvent(shipmentHistoryChartRef.current, event);
            event.currentTarget.style.cursor = element.length > 0 ? "pointer" : "default";
          }
        }}
      />
    );
  }

  return null;
};

export default Chart;
