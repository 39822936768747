import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchSalesReps,
  fetchDsms,
  getAccessControl,
  getSiteContactEmail,
  getSiteSelectionList,
  requestNewAccount,
  getVersion,
} from "./app.actions";
import {
  AppVersion,
  SiteSelection,
  AccessControl,
  ErrorResponse,
} from "../types/types";
import { RootState } from "./store";

type InitialState = {
  accessControl: AccessControl;
  loading: boolean;
  error?: string | ErrorResponse;
  siteSelections: SiteSelection[];
  inventorySiteSelections: SiteSelection[];
  preferencesSiteSelections: SiteSelection[];
  siteContactEmail?: string;
  appVersion?: AppVersion;
  portalOutOfDate: boolean;
  loadAppVersionStatus: "idle" | "pending";
};

const initialState: InitialState = {
  accessControl: {
    message: "",
    accessRestriction: "none",
    restrictRollingAccess: false,
    restrictStockAccess: false,
    restrictReviewRollingWeightView: false
  },
  siteSelections: [],
  inventorySiteSelections: [],
  preferencesSiteSelections: [],
  loading: false,
  loadAppVersionStatus: "idle",
  portalOutOfDate: false,
};

const getSites = (sites: SiteSelection[]) => {
  const northSites =
    sites.find((site) => site.key === "North")?.sites ?? [];
  const southSites =
    sites.find((site) => site.key === "South")?.sites ?? [];
  const s = [
    {
      key: "ALL",
      description: "All",
      sites: [...northSites, ...southSites],
    },
    ...sites,
  ];
  return s;
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string>) => {
      state.accessControl.message = action.payload;
    },
    showPortalOutOfDateAlert: (state) => {
      state.portalOutOfDate = true;
    },
    dismissPortalOutOfDateAlert: (state) => {
      state.portalOutOfDate = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessControl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccessControl.fulfilled, (state, action) => {
      state.loading = false;
      state.accessControl = action.payload;
    });
    builder.addCase(getAccessControl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSiteSelectionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSiteSelectionList.fulfilled, (state, action) => {
      state.loading = false;

      state.siteSelections = getSites(action.payload.all);
      state.inventorySiteSelections = getSites(action.payload.inventory);
      state.preferencesSiteSelections = getSites(action.payload.preferences);
    });
    builder.addCase(getSiteSelectionList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchSalesReps.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSalesReps.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSalesReps.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchDsms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDsms.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchDsms.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestNewAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestNewAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(requestNewAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSiteContactEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSiteContactEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.siteContactEmail = action.payload.email;
    });
    builder.addCase(getSiteContactEmail.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getVersion.pending, (state) => {
      state.loadAppVersionStatus = "pending";
    });
    builder.addCase(getVersion.fulfilled, (state, action) => {
      state.loadAppVersionStatus = "idle";
      state.appVersion = action.payload;
    });
    builder.addCase(getVersion.rejected, (state) => {
      state.loadAppVersionStatus = "idle";
    });
  },
});

export const selectMillsForSelectComponent = createSelector(
  (state: RootState) => state.app.siteSelections,
  (sites) => {
    const m = sites.map((mill) => ({
      label: mill.description,
      value: mill.key,
      sites: mill.sites,
    }));
    return m;
  }
);

export const selectInventoryMillsForSelectComponent = createSelector(
  (state: RootState) => state.app.inventorySiteSelections,
  (sites) => {
    const m = sites.map((mill) => ({
      label: mill.description,
      value: mill.key,
      sites: mill.sites,
    }));
    return m;
  }
);

export default appSlice.reducer;
export const { showPortalOutOfDateAlert, dismissPortalOutOfDateAlert } =
  appSlice.actions;
