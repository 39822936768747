import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountSummary, Customer_Customer, ReadyToShipSummary } from "../types/types";
import {
  fetchCustomers,
  requestNewShipTo,
  fetchCustomerSummary,
  setCurrentCustomerDetails,
  fetchCustomerReadyToShipSummary,
} from "./customer.actions";
import { RootState } from "./store";

type InitialState = {
  currentCustomer?: Customer_Customer;
  currentShipTo?: string;
  currentCustomerSummary?: AccountSummary;
  readyToShipSummary?: ReadyToShipSummary;
  loading: boolean;
  error?: string;
};

const initialState: InitialState = {
  currentCustomer: undefined,
  currentShipTo: undefined,
  currentCustomerSummary: undefined,
  readyToShipSummary: undefined,
  loading: false,
  error: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCurrentShipTo: (state, action: PayloadAction<string>) => {
      state.currentShipTo = action.payload;
    },
    setCurrentCustomer: (state, action: PayloadAction<Customer_Customer>) => {
      state.currentCustomer = action.payload;
      state.currentShipTo =
        action.payload.shipTos.length > 0 ? action.payload.shipTos[0].id : "";
    },
    setCurrentCustomerVmiChecked: (state, action: PayloadAction<boolean>) => {
      if (state.currentCustomer) {
        state.currentCustomer.vmi = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentCustomerDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(setCurrentCustomerDetails.fulfilled, (state, actions) => {
      state.loading = false;
      state.currentCustomer = actions.payload;
      state.currentShipTo =
        actions.payload?.shipTos?.length > 0
          ? actions.payload.shipTos[0].id
          : "";
    });
    builder.addCase(setCurrentCustomerDetails.rejected, (state, actions) => {
      state.loading = false;
      state.error = actions.error.message;
    });
    builder.addCase(fetchCustomers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchCustomers.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchCustomers.rejected, (state, actions) => {
      state.loading = false;
      state.error = actions.error.message;
    });
    builder.addCase(requestNewShipTo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(requestNewShipTo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(requestNewShipTo.rejected, (state, actions) => {
      state.loading = false;
      state.error = actions.error.message;
    });
    builder.addCase(fetchCustomerSummary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchCustomerSummary.fulfilled, (state, actions) => {
      state.loading = false;
      state.currentCustomerSummary = actions.payload;
    });
    builder.addCase(fetchCustomerSummary.rejected, (state, actions) => {
      state.loading = false;
      state.error = actions.error.message;
    });
    builder.addCase(fetchCustomerReadyToShipSummary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchCustomerReadyToShipSummary.fulfilled, (state, actions) => {
      state.loading = false;
      state.readyToShipSummary = actions.payload;
    });
    builder.addCase(fetchCustomerReadyToShipSummary.rejected, (state, actions) => {
      state.loading = false;
      state.error = actions.error.message;
    });
  },
});

export const selectCurrentCustomerShipTos = createSelector(
  (state: RootState) => state.customer.currentCustomer,
  (customer) => [
    { value: "all", label: "All" },
    ...(customer?.shipTos.map((shipTo) => ({
      value: shipTo.id,
      label: `${shipTo.id} - ${shipTo.name}`,
    })) ?? []),
  ]
);

export const selectCurrentCustomerId = createSelector(
  (state: RootState) => state.customer.currentCustomer,
  (customer) => customer?.id
);

export const selectCurrentCustomerSummary = (state: RootState) =>
  state.customer.currentCustomerSummary;

export const selectReadyToShipSummary = (state: RootState) =>
  state.customer.readyToShipSummary;

export const selectCurrentShipTo = (state: RootState) =>
  state.customer.currentShipTo;

export const selectShipToDropdownData = createSelector(
  (state: RootState) => state.customer.currentCustomer,
  (currentCustomer) => {
    return (
      currentCustomer?.shipTos.map((item) => {
        const deliveryMode = item.deliveryMode
          ? ` - ${item.deliveryMode.toUpperCase()}`
          : "";
        const pricingModel = item.pricingModel
          ? ` - ${item.pricingModel.toUpperCase()}`
          : "";
        return {
          value: item.id,
          selectedLabel: `${item.id} - ${item.name}`,
          label: `${item.id} - ${item.name} ${deliveryMode}${pricingModel}`,
        };
      }) ?? []
    );
  }
);

export default customerSlice.reducer;
export const { setCurrentShipTo, setCurrentCustomer, setCurrentCustomerVmiChecked } = customerSlice.actions;
