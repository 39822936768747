/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateAccessControl } from "../../../store/admin.actions";
import { AccessControl, AccessRestrictionType } from "../../../types/types";
import Button from "../../../components/atoms/Button";
import Select from "../../../components/atoms/Select";
import ErrorDisplay from "../../../components/molecules/ErrorDisplay";
import { getAccessControl } from "../../../store/app.actions";

type Props = {
  children?: ReactNode;
};

const Announcements: React.FC<Props> = () => {
  const accessControl = useAppSelector(
    (state) => state.user.currentContext?.accessControl
  );
  const [announcementEnabled, setAnnouncementEnabled] = useState(false);
  const [message, setMessage] = useState("");
  const [restrictStock, setRestrictStock] = useState(false);
  const [restrictRolling, setRestrictRolling] = useState(false);
  const [restrictAvailableWeight, setRestrictAvailableWeight] = useState(false);
  
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.admin);
  const [successMessage, setSuccessMessage] = useState("");
  const [accessRestriction, setAccessRestriction] =
    useState<AccessRestrictionType>("none");
  const [error, setError] = useState("");
  const [status, setStatus] = useState<"idle" | "loading">("idle");

  useEffect(() => {
    setStatus("loading");
    dispatch(getAccessControl())
      .then(() => {
        resetHandler();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setStatus("idle");
      });
  }, []);

  useEffect(() => {
    resetHandler();
  }, [accessControl]);

  const resetHandler = () => {
    if (accessControl) {
      setMessage(accessControl.message);
      setAnnouncementEnabled(accessControl.message !== "");
      setRestrictStock(accessControl.restrictStockAccess);
      setRestrictRolling(accessControl.restrictRollingAccess);
      setAccessRestriction(accessControl.accessRestriction);
      setRestrictAvailableWeight(accessControl.restrictReviewRollingWeightView);
    }
  };

  const formHandler = () => {
    setMessage("");
    const settings: AccessControl = {
      message: message,
      restrictRollingAccess: restrictRolling,
      restrictStockAccess: restrictStock,
      accessRestriction: accessRestriction,
      restrictReviewRollingWeightView: restrictAvailableWeight
    };
    dispatch(updateAccessControl(settings))
      .unwrap()
      .then(() => {
        setSuccessMessage("Settings updated successfully");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <ErrorDisplay error={error} />
      <form onSubmit={formHandler} css={tw`text-xs px-8 max-w-4xl`}>
        <h3 css={[tw`font-bold`]}>Customer Home Announcements Message</h3>
        <fieldset disabled={status === "loading" || loading}>
          <div css={tw`flex items-center mt-2`}>
            <div css={tw`flex items-center w-1/2`}>
              <label htmlFor="announcementEnabled" css={tw`mr-3`}>
                Show Announcement:
              </label>
              <input
                checked={announcementEnabled}
                onChange={(e) => {
                  setAnnouncementEnabled(e.target.checked);
                  if (!e.target.checked) {
                    setMessage("");
                  }
                }}
                name="announcementEnabled"
                type="checkbox"
              />
            </div>
            <textarea
              disabled={!announcementEnabled}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              css={tw`h-20 w-full resize-none`}
            />
          </div>

          <div css={tw`flex items-center mt-4`}>
            <h3 css={tw`font-bold`}>Restrict External Users Access To:</h3>
            <input
              css={tw`ml-2`}
              checked={restrictStock}
              onChange={(e) => setRestrictStock(e.target.checked)}
              type="checkbox"
              id="restrictStock"
            />
            <label css={tw`ml-1 cursor-pointer`} htmlFor="restrictStock">Stock</label>
          
            <input
              css={tw`ml-3`}
              checked={restrictRolling}
              onChange={(e) => setRestrictRolling(e.target.checked)}
              type="checkbox"
              id="restrictRolling"
            />
            <label css={tw`ml-1 cursor-pointer`} htmlFor="restrictRolling">Rollings</label>
          </div>
          
          <div css={tw`flex items-center mt-4`}>
            <h3 css={tw`font-bold`}>Restrict Available Weight View on Rollings:</h3>
            <input
              css={tw`ml-2`}
              checked={restrictAvailableWeight}
              onChange={(e) => setRestrictAvailableWeight(e.target.checked)}
              type="checkbox"
              id="restrictAvailableWeight"
            />
          </div>

          <div css={tw`flex items-center mt-4`}>
            <label css={tw`font-bold mr-2`} htmlFor="accessControl">
              Portal Access Level:
            </label>
            <Select
              minWidth="15ch"
              name="accessControl"
              data={[
                { value: "none", label: "All" },
                { value: "internal", label: "Internal" },
                { value: "admin", label: "Admin" },
                { value: "itAdmin", label: "IT Admin" },
              ]}
              value={accessRestriction}
              onChange={(v: AccessRestrictionType) => setAccessRestriction(v)}
            />
          </div>
          <div css={tw`mt-5`}>
            <Button
              onClick={resetHandler}
              css={tw`text-xs w-16 text-center px-0 py-[2px] font-normal`}
              type="button"
            >
              Cancel
            </Button>
            <Button
              onClick={formHandler}
              disabled={loading}
              type="button"
              css={tw`text-xs ml-2 w-16 text-center px-0 py-[2px] font-normal`}
            >
              Save
            </Button>
          </div>
        </fieldset>
        {successMessage && (
          <p css={tw`text-nucor-green mt-2`}>{successMessage}</p>
        )}
      </form>
    </>
  );
};

export default Announcements;
